import { FormikErrors } from 'formik';
import { useState, createContext, Dispatch, SetStateAction } from 'react';

export type OnboardingErrorsType = FormikErrors<Record<string, Record<string, string>>>

export type OnboardingContextType = {
  isOnboarded: boolean,
  onboardingErrors: OnboardingErrorsType,
  setIsOnboarded: Dispatch<SetStateAction<boolean>>,
  setOnboardingErrors: Dispatch<SetStateAction<OnboardingErrorsType>>
}

export const OnboardingContext = createContext<OnboardingContextType | null>(null);

export const OnboardingContextProvider = ({ children }: { children: JSX.Element }) => {
  // the value that will be given to the context
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [onboardingErrors, setOnboardingErrors] = useState<OnboardingErrorsType>({});

  return (
    // the Provider gives access to the context to its children
    <OnboardingContext.Provider value={{ isOnboarded, onboardingErrors, setIsOnboarded, setOnboardingErrors }}>
      {children}
    </OnboardingContext.Provider>
  );
};
