import React, {
  useEffect,
  useState,
  useMemo,
  useContext
} from 'react';
import { useFormikContext } from 'formik';
import { TextInput } from '@justworkshr/milo-form';
import { SystemIcon } from '@justworkshr/milo-icons';
import parsePhoneNumber, {
  type CountryCode,
  getCountryCallingCode
} from 'libphonenumber-js';

import styles from './PhoneNumberInput.module.css';
import { useTranslation } from 'react-i18next';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';

type Props = {
  id?: string;
  className?: string;
  name: string;
  onChange: (event: React.ChangeEvent) => void;
  onBlur: (event: React.FocusEvent) => void;
  value: string;
  countryCode?: CountryCode;
};

function removeNonDigits(arg: string) {
  return arg.replace(/\D/g, '')
}

const PhoneNumberInput = ({
  id,
  className,
  name,
  onBlur,
  countryCode = 'CA',
  value
}: Props) => {
  const [caretStartString, setCaretStartString] = useState<string | null >(null);
  const [caretEndString, setCaretEndString] = useState<string | null>(null);
  const [globalTarget, setGlobalTarget] = useState<EventTarget & HTMLInputElement>();

  const { t } = useTranslation();
  const { supportedCountryMap } = useContext(SupportedCountriesContext);
  const supportedCountries = useMemo(
    () =>
      Object.values(supportedCountryMap).map((country) => {
        const phoneCountryCode = `+${getCountryCallingCode(
          country.alpha2Code as CountryCode
        )}`;
        return {
          alpha2Code: country.alpha2Code,
          commonName: country.name,
          emojiFlag: country.emojiFlag,
          phoneCountryCode
        };
      }) || [],
    [supportedCountryMap]
  );

  const [alpha2Code, setAlpha2Code] = useState<string>(countryCode)
  const [countryPhoneCode, setCountryPhoneCode] = useState<string>(
    parsePhoneNumber(value)?.countryCallingCode ? `+${parsePhoneNumber(value)?.countryCallingCode}` : `+${getCountryCallingCode(countryCode as CountryCode)}`
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(value || '');
  const { setFieldValue, setFieldTouched, touched } = useFormikContext();

  // Check whether the form has just loaded and don't validate if so
  const isFormTouched = Object.keys(touched).length > 1

  useEffect(() => {
    let tempNumber = phoneNumber;

    if (!tempNumber) {
      return;
    }
    if (!tempNumber.startsWith('+')) {
      tempNumber = `${countryPhoneCode}${tempNumber}`
    }

    const parsedPhoneNumber = parsePhoneNumber(tempNumber, countryCode);

    if (parsedPhoneNumber) {
      // country calling code does not include the plus sign, but it is
      // required for us to parse and validate phone numbers
      setCountryPhoneCode(`+${parsedPhoneNumber.countryCallingCode}`);
      setPhoneNumber(parsedPhoneNumber.formatNational());
      setFieldValue(name, parsedPhoneNumber.number, isFormTouched);
    } else {
      setPhoneNumber(tempNumber)
      setFieldValue(name, tempNumber, isFormTouched)
    }

    /*
    Finds the positions in the newly formatted (NF) number such that they line up with the positions in the previous unformatted (UF) number

    The reason why use find new indices instead of just using the old ones is because of robustness --- since formatting can add/remove characters, a specific position i may no longer correspond to the same "perceptual position"

    Example:
      Let | denote the current cursor
      In 23|45678901, the cursor will be at position 3.

      Assuming that this is formatted as a US number, using the previous cursor position gives us (2|34) 567-8901. This is jarring, as most people expectations would be that the cursor would be (23|4) 567-8901.

      The code below solves this issue. We use str[i:] instead of str[:i] as it is much less likely that formatting adds numbers to the end of a number than the beginning (which can happen in the case of ie, a national prefix).
    */
    if (globalTarget && caretEndString && caretStartString) {
      let caretStart = null;
      let caretEnd = null;

      for (let i = 0; i < phoneNumber.length; i++) {
        if (caretStart == null) {
          if (removeNonDigits(phoneNumber.slice(i)) === caretStartString) {
            caretStart = i
          }
        }

        if (caretEnd == null) {
          if (removeNonDigits(phoneNumber.slice(i)) === caretEndString) {
            caretEnd = i
          }
        }

        if ((caretStart !== null) && (caretEnd !== null)) {
          break;
        }
      }
      if ((caretStart !== null) && (caretEnd !== null)) {
        globalTarget.setSelectionRange(caretStart, caretEnd);
      }
    }
  }, [phoneNumber, countryPhoneCode, setFieldValue, name]);

  useEffect(() => {
    // to show error when switching country phone codes on a pre-populated field
    setCountryPhoneCode(countryPhoneCode);
    if (phoneNumber) {
      setFieldTouched(name, true, isFormTouched);
    }
  }, [
    countryPhoneCode
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /*
    Get all numbers from the cursor to the end of the string

    This is used later when calculating the new curor position

    Technically, there are two carets (to denote a selection) --- however, in almost all cases after a change (eg, insertion, deletion by backspace, deletion by selection, etc.), they are both the same (ie, there is no selection)

    We still save both pieces of information as there may be some case where a selection occurs that was not anticipated beforehand.
    */
    setCaretStartString(removeNonDigits(event.currentTarget.value.slice(event.target.selectionStart!)));
    setCaretEndString(removeNonDigits(event.currentTarget.value.slice(event.target.selectionEnd!)));
    setGlobalTarget(event.target);

    setPhoneNumber(event.currentTarget.value);
    setFieldTouched(name, true, true);
  };

  const handlePhoneCountryCodeChange = (countryCode: string) => {
    const phoneCountryCode = supportedCountryMap[countryCode].phoneCountryCode
    setAlpha2Code(countryCode)
    setCountryPhoneCode(phoneCountryCode)
  }

  return (
    <div className={className}>
      <div className={styles.countrySelectContainer}>
        <select
          className={styles.countryCodeSelect}
          value={alpha2Code}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            handlePhoneCountryCodeChange(event.currentTarget.value)
          }
          required
        >
          {supportedCountries.map((country) => {
            return (
              <option
                value={country.alpha2Code}
                key={country.commonName}
              >
                {`${country.emojiFlag} ${
                  country.commonName ? t(country.commonName) : ''
                } ${country.phoneCountryCode}`}
              </option>
            );
          })}
        </select>

        <div className={styles.countryFlag}>{supportedCountryMap ? supportedCountryMap[alpha2Code].emojiFlag : ''}</div>
        <SystemIcon className={styles.chevronIcon} iconName='chevron-down' />
        <span className={styles.countryCode}>{countryPhoneCode}</span>

        <div className={styles.phoneNumberInput}>
          <TextInput
            id={id}
            name={name}
            value={phoneNumber}
            onBlur={onBlur}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberInput;
