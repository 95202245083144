import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import AddressFormField from './AddressFormField';
import { useAddressFormatter } from './useAddressFormatter';
import { FieldName } from '@shopify/address';
import styles from './AddressForm.module.css';
import { addressFields } from './constants';
import { CountryCode } from 'libphonenumber-js';
import { ProfileFormValues } from '../ProfileForm/types';
import { AddressFieldType } from 'graphql/queries/AddressFormQueries';
import CustomAddressFormField from './CustomAddressFormField';

export type FormattedLabelsType = {
  address1: string;
  address2: string;
  city: string;
  country: string;
  postalCode: string;
  zone: string;
  [key: string]: string;
};

type Props = {
  handleBlur: (e: React.FocusEvent<Element, Element>, name?: string) => void;
  handleCountryChange: (value: CountryCode) => void;
  isProfileEditMode?: boolean;
}

const AddressForm = ({ handleBlur, handleCountryChange, isProfileEditMode }: Props) => {
  const { values } = useFormikContext<ProfileFormValues>();

  const { countryCode } = values.address;
  const { orderedFields, customOrderedFields } = useAddressFormatter(countryCode);

  const renderAddressFormField = (fieldName: FieldName) => {
    const mappedFieldName = addressFields[fieldName];
    if (!mappedFieldName) return null;

    return (
      <div className={styles.formFieldPadding} key={mappedFieldName}>
        <AddressFormField
          fieldName={fieldName}
          formPath='address'
          handleBlur={handleBlur}
          handleCountryChange={handleCountryChange}
          isProfileEditMode={isProfileEditMode}
        />
      </div>
    );
  };

  const renderCustomAddressFormField = (field: AddressFieldType) => {
    return (
      <div className={styles.formFieldPadding} key={field.name}>
        <CustomAddressFormField
          field={field}
          formPath='address'
          handleBlur={handleBlur}
          handleCountryChange={handleCountryChange}
          isProfileEditMode={isProfileEditMode}
        />
      </div>
    );
  };

  const renderedFields = useMemo(() => (
    orderedFields?.flat().map((fieldName: FieldName, index: number) => (
      <React.Fragment key={`${index}-${fieldName}`}>
        {renderAddressFormField(fieldName)}
      </React.Fragment>
    ))
  ), [orderedFields]);

  const renderedCustomFields = useMemo(() => {
    const countryField: AddressFieldType = { name: 'countryCode', label: 'Country/region', required: true }
    return (
      [countryField, ...(customOrderedFields || [])].map((field: AddressFieldType, index: number) => (
        <React.Fragment key={`${index}-${field.name}`}>
          {renderCustomAddressFormField(field)}
        </React.Fragment>
      ))
    )
  }, [customOrderedFields]);

  return <>{customOrderedFields ? renderedCustomFields : renderedFields}</>;
};

export default AddressForm;
