import React from 'react'
import { getIn, useFormikContext } from 'formik';
import { AutoFormField } from '../AutoForm'
import { FieldTypes } from 'utils/types/formTypes';
import { useAddressFormatter } from './useAddressFormatter';
import { COUNTRIES_WITHOUT_SUBDIVISION, autoCompleteMap, addressFields, fieldTypeMap, labelsMap } from './constants';
import { useContext } from 'react';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { CountryCode } from 'libphonenumber-js';
import { t } from 'i18next';

type Props = {
  fieldName: string;
  formPath: string;
  handleBlur: (e: React.FocusEvent<Element, Element>, name?: string) => void;
  handleCountryChange?: (value: CountryCode) => void;
  isProfileEditMode?: boolean;
};

type FormattedLabelsType = {
  address1: string;
  address2: string;
  city: string;
  country: string;
  postalCode: string;
  zone: string;
  [key: string]: string;
};

const AddressFormField = ({ fieldName, formPath, handleBlur, handleCountryChange, isProfileEditMode }: Props) => {
  const { values, handleChange } = useFormikContext<any>()

  const mappedFieldName = addressFields[fieldName];
  const type = fieldTypeMap[mappedFieldName]
  const name = `${formPath}.${mappedFieldName}`
  const value = getIn(values, name)

  const { countryCode } = values.address;
  const { country } = useAddressFormatter(countryCode);

  const { generateCountryOptions, generateStateOptions } = useContext(SupportedCountriesContext);
  const countries = generateCountryOptions()

  const formattedLabels: FormattedLabelsType | undefined = country?.labels;
  const labelKey = labelsMap[fieldName]

  const getLabel = () => {
    if (!formattedLabels) {
      return mappedFieldName
    }
    return formattedLabels[labelKey] || mappedFieldName
  }

  const getAutoCompleteValue = () => {
    return autoCompleteMap[mappedFieldName] || undefined;
  }

  const states = generateStateOptions(countryCode)

  const getOptions = () => {
    switch (fieldName) {
      case 'province':
        return states
      case 'country':
        return countries
      default:
        return []
    }
  }

  const isCountryFieldDisabled = isProfileEditMode && mappedFieldName === 'countryCode';

  if (fieldName === 'province' && COUNTRIES_WITHOUT_SUBDIVISION.includes(countryCode)) {
    return null
  }

  return (
    <AutoFormField
      key={mappedFieldName}
      type={type as FieldTypes}
      name={name}
      label={getLabel()}
      optional={mappedFieldName === 'streetLine2'}
      placeholder={type === 'select' ? t('common.selectOne') : undefined}
      handleChange={(e) => {
        handleChange(e)
        if (handleCountryChange && mappedFieldName === 'countryCode') {
          handleCountryChange(e.currentTarget.value as CountryCode)
        }
      }}
      handleBlur={handleBlur}
      value={value}
      options={getOptions()}
      disabled={!!isCountryFieldDisabled}
      autoComplete={getAutoCompleteValue()}
    />
  )
}
export default AddressFormField
