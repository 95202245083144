export const NATIONAL_ID_COUNTRY_CODES = ['AE', 'AR', 'BD', 'BR', 'CL', 'CO', 'CR', 'DO', 'GT', 'PE', 'PY', 'UY']

// To use with stdout for id verification
// https://www.npmjs.com/package/stdnum
export const COUNTRY_NATIONAL_ID_TYPES: { [key: string]: string } = {
  AE: 'cedula',
  AR: 'cuil',
  BD: 'cedula',
  BR: 'cpf',
  CL: 'rut',
  CO: 'cedula',
  CR: 'cedula',
  DO: 'cedula',
  GT: 'nit',
  PE: 'cedula',
  PY: 'cedula',
  UY: 'cedula'
}

export const COUNTRY_NATIONAL_ID_PLACEHOLDERS: { [key: string]: string } = {
  AE: 'Emirates Identity Card (EID)',
  AR: 'Código Único de Identificación Laboral (CUIL)',
  BD: 'National identity card (NID)',
  BR: 'Cadastro de Pessoas Físicas (CPF)',
  CL: 'Rol Único Tributario',
  CO: 'Cédula de Ciudadanía (CC)',
  CR: 'Cédula de Identidad (CI)',
  DO: 'Cédula de Identidad y Electoral (CIE)',
  GT: 'Número de Identification Tributaria (NIT)',
  PE: 'Código Único de Identificación (CUI)',
  PY: 'Cédula de identidad civil (CI)',
  UY: 'Cédula de Identidad (CI)'
}
