export function isNestedEmpty(obj: { [key: string]: any }): boolean {
  for (const key in obj) {
    if (obj[key] instanceof Object) {
      if (!isNestedEmpty(obj[key])) {
        return false
      }
    // The value is an array or string
    } else {
      // If array or string has length not equal to 0
      if (obj[key].length !== 0) {
        return false
      }
    }
  }
  return true;
}
