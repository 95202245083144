import { CountryCode } from 'libphonenumber-js';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { FieldName } from '@shopify/address';
import { COUNTRIES_WITHOUT_SUBDIVISION, COUNTRIES_WITHOUT_POSTAL_CODE, POSTAL_CODE } from './constants';
import { OnboardingErrorsType } from 'contexts/Onboarding';

type CountryFormValidationSchemaType = {
  streetLine1: Yup.StringSchema;
  streetLine2: Yup.StringSchema;
  city: Yup.StringSchema;
  postalCode: Yup.StringSchema;
  state?: Yup.StringSchema;
}

const formTranslation = (t: TFunction, key: string) => t(`formValidations.${key}`)

export const getAddressFormSchema = (countryCode: CountryCode = 'CA', fields: FieldName[][] | null, onboardingErrors: OnboardingErrorsType) => {
  const hasState = fields?.flat().includes(FieldName.Zone) && !COUNTRIES_WITHOUT_SUBDIVISION.includes(countryCode)
  const hasPostalCode = !COUNTRIES_WITHOUT_POSTAL_CODE.includes(countryCode)
  const postalCodeRegex = POSTAL_CODE[countryCode]

  return (t: TFunction) => Yup.object<CountryFormValidationSchemaType>().shape({
    streetLine1: Yup.string()
      .required(formTranslation(t, 'required'))
      .test('valid street line 1', onboardingErrors?.address?.streetLine1 || '', () => !onboardingErrors?.address?.streetLine1),
    streetLine2: Yup.string()
      .test('valid street line 1', onboardingErrors?.address?.streetLine2 || '', () => !onboardingErrors?.address?.streetLine2),
    city: Yup.string()
      .required(formTranslation(t, 'required'))
      .test('valid city', onboardingErrors?.address?.city || '', () => !onboardingErrors?.address?.city),
    ...(hasState ? {
      state: Yup.string()
        .required(formTranslation(t, 'required'))
        .test('valid state', onboardingErrors?.address?.state || '', () => !onboardingErrors?.address?.state)
    } : {}),
    ...(hasPostalCode ? {
      postalCode: Yup.string().trim()
        .matches(postalCodeRegex, formTranslation(t, 'invalidPostalCode'))
        .required(formTranslation(t, 'required'))
        .test('valid postal code', onboardingErrors?.address?.postalCode || '', () => !onboardingErrors?.address?.postalCode)
    } : { postalCode: Yup.string().notRequired() })
  })
}
