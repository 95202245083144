/** This FormField component wraps the Milo 3 FormField component and is intended
 * to be used with Formik. **/
import React, { useContext } from 'react';
import { getIn, useFormikContext } from 'formik';
import { FormField } from '@justworkshr/milo-form'
import { OnboardingContext } from 'contexts/Onboarding';

interface Props {
  children: any;
  id?: string;
  label?: string;
  message?: string;
  name?: string; // Formik field path
  required?: boolean;
}

const FormikFormField : React.FC<Props> = ({ children, id, label, message, name, required }) => {
  const { errors, touched } = useFormikContext()
  const onboardingContext = useContext(OnboardingContext)

  const error = name ? getIn(errors, name) : ''
  const isTouchedField = name ? getIn(touched, name) : false
  const isOnboardingError = Object.keys(onboardingContext?.onboardingErrors || {}).length > 0
  const errorMessage = isOnboardingError ? error : isTouchedField && error

  return (
    <FormField
      name={id}
      label={label || '\u200B'}
      message={message}
      required={required}
      error={errorMessage}
    >
      {children}
    </FormField>
  )
};

export default FormikFormField;
