import React from 'react'
import { getIn, useFormikContext } from 'formik';
import { AutoFormField } from '../AutoForm'
import { FieldTypes } from 'utils/types/formTypes';
import { COUNTRIES_WITHOUT_SUBDIVISION, fieldTypeMap } from './constants';
import { useContext } from 'react';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { CountryCode } from 'libphonenumber-js';
import { t } from 'i18next';
import { AddressFieldType } from 'graphql/queries/AddressFormQueries';

type Props = {
  field: AddressFieldType;
  formPath: string;
  handleBlur: (e: React.FocusEvent<Element, Element>, name?: string) => void;
  handleCountryChange?: (value: CountryCode) => void;
  isProfileEditMode?: boolean;
};

const CustomAddressFormField = ({ field, formPath, handleBlur, handleCountryChange, isProfileEditMode }: Props) => {
  const { values, handleChange } = useFormikContext<any>()

  const type = fieldTypeMap[field.name]
  const name = `${formPath}.${field.name}`
  const value = getIn(values, name)

  const { countryCode } = values.address;

  const { generateCountryOptions, generateStateOptions } = useContext(SupportedCountriesContext);
  const countries = generateCountryOptions()

  const states = generateStateOptions(countryCode)

  const getOptions = () => {
    switch (field.name) {
      case 'state':
        return states
      case 'countryCode':
        return countries
      default:
        return []
    }
  }

  const isCountryFieldDisabled = isProfileEditMode && field.name === 'countryCode';

  if (field.name === 'state' && COUNTRIES_WITHOUT_SUBDIVISION.includes(countryCode)) {
    return null
  }

  return (
    <AutoFormField
      key={field.name}
      type={type as FieldTypes}
      name={name}
      label={field.label}
      optional={!field.required}
      placeholder={type === 'select' ? t('common.selectOne') : undefined}
      handleChange={(e) => {
        handleChange(e)
        if (handleCountryChange && field.name === 'countryCode') {
          handleCountryChange(e.currentTarget.value as CountryCode)
        }
      }}
      handleBlur={handleBlur}
      value={value}
      options={getOptions()}
      disabled={!!isCountryFieldDisabled}
      autoComplete={field.autoComplete}
    />
  )
}
export default CustomAddressFormField
